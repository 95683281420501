import axios from 'axios'

export default axios.create({
    baseURL: process.env.VUE_APP_API_URL ?? 'https://api.sites.netwerkdiensten.be/',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      //'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
})

