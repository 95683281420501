import Vue from 'vue'
import Router from 'vue-router'
import store from "./store/store"


Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/map',
    },
    {
      path: '/authenticate',
      name: 'authenticate',
      component: () => import('./auth/Authenticate'),
      props: route => ({
        uid: route.query.uid,
        token: route.query.token
      })
    },
    {
      path: '/submissions',
      name: 'submission.overview',
      component: () => import('./views/SubmissionOverview'),
    },
    {
      path: '/submissions/:id',
      name: 'submission.detail',
      component: () => import('./views/SubmissionDetail'),
    },
    {
      path: '/submissions/:id/onboarding',
      name: 'submission.onboarding',
      component: () => import('./views/Onboarding'),
      props: route => ({read: route.params.read})
    },
    {
      path: '/submissions/:id/onboarding',
      name: 'submission.onboarding.devices',
      component: () => import('./views/Onboarding'),
      props: {step: 3}
    },
    {
      path: '/submissions/:submission_id/devices/:device_id/onboarding',
      name: 'device.onboarding',
      component: () => import('./views/DeviceOnboarding'),
    },
    {
      path: '/labels',
      name: 'labels.overview',
      component: () => import('./views/LabelsOverview'),
    },
    {
      path: '/vlans',
      name: 'vlans.overview',
      component: () => import('./views/VlansOverview'),
    },
    {
      path: '/map',
      name: 'buildings.map',
      component: () => import('./views/BuildingsMap'),
      props: true,
      children: [
        {
          path: '/map',
          name: 'buildings.overview.side',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          components: {
            side_view: () => import(/* webpackChunkName: "projects" */ './views/BuildingsOverviewSide')
          },
        },
        {
          path: '/map/building/:code',
          name: 'buildings.map.detail',
          props: true,
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          components: {
            side_view: () => import(/* webpackChunkName: "projects" */ './components/buildings/BuildingDetailComponent')
          },
        },]
    },
  ]

})

router.beforeEach((to, from, next) => {

  if (to.path !== '/login' && to.name !== 'authenticate' && !store.getters.authenticated) {
    // const loginpath = window.location.pathname;
    window.location.href = (process.env.VUE_APP_API_URL ?? 'https://api.sites.netwerkdiensten.be/') + 'accounts/login/';
  } else if (to.path === '/login' && store.getters.authenticated) {
    next('/')
  } else {
    next();
  }
})


export default router
