import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'

import store from './store/store'
import router from './router'
import api from './api'

/// Init Axios (HTTP)
Vue.prototype.$axios = api
Vuex.Store.prototype.$axios = api

// Axios Interceptor such  that every request will be sent using a header
api.interceptors.request.use (
  function (config) {
    const token = localStorage.getItem('jwt');
    if (token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("jwt")
    }
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

/* Moment */
const moment = require('moment')
require('moment/locale/nl')
Vue.use(require('vue-moment'), {
    moment
})


/* Buefy */
import '@/scss/theme-default.scss'
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Buefy)

/* Mapbox */
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

/* Vue JSON CSV */
import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

new Vue({
  store,
  api,
  router,
  mapboxgl,
  render: h => h(App),
}).$mount('#app')
