import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// States
const state = {
  title: {
    message: "Welcome",
    stack: [],
    back: false
  },

  current_filters: null,

  currentJWT: null,
  user: {},

  submissions: [],
  current_submission: {},
  buildings: [],
  filtered_buildings: [],
  current_building: null,
  relocating: false,
  relocated_location: null,
  vlans: [],
  labels: [],

}

// Getter functions
const getters = {
  authenticated: state => state.currentJWT !== null,
  jwt: state => state.currentJWT,
  jwtData: (state, getters) => state.currentJWT ? JSON.parse(atob(getters.jwt.split('.')[1])) : null,
  jwtSubject: (state, getters) => getters.jwtData ? getters.jwtData.sub : null,
  jwtIssuer: (state, getters) => getters.jwtData ? getters.jwtData.iss : null
}


// Actions
const actions = {
  setJWT({commit}, jwt) {
    commit('setJWT', jwt)
    return this.$axios.get('/me/').then((response) => {
      commit('SET_USER', response.data)
    }).catch(() => {
      commit('SET_USER', null)
    })
  },
  startRelocating({commit}, val) {
    commit('SET_RELOCATING', val)
  },
  setTitle({commit}, title) {
    commit('setTitle', title)
  },
  setCurrentBuilding({commit}, building) {
    commit('SET_CURRENT_BUILDING', building)
  },
  setRelocatedLocation({commit}, building) {
    commit('SET_RELOCATING_LOCATION', building)
  },
  updateBuildingLocationInFilteredBuildings({commit}, building) {
    commit("UPDATE_OR_SET_BUILDING", building)
  },

  // eslint-disable-next-line no-unused-vars
  fetchAllLabels({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/labels/`)
          .then((response) => {
            commit('UPDATE_LABELS', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  createLabel({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/labels/`,
          payload
      )
          .then((response) => {
            resolve(response)
            commit('ADD_LABEL', response.data)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  fetchSubmissions({commit}) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/submissions/`)
          .then((response) => {
            commit('UPDATE_SUBMISSION', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchSubmissionDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/submissions/${id}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  storeSubmissionData({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/submissions/${payload.id}/`,
          payload.data
      )
          .then((response) => {
            resolve(response)
            commit('UPDATE_SUBMISSION', response.data)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchSubmissionDevices({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/submissions/${id}/devices/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  newSubmission({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/submissions/`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  deleteSubmission({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/submissions/${payload.id}`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  submitSubmission({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/submissions/${id}/submit/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  approveSubmission({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/submissions/${id}/approve/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  refuseSubmission({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/submissions/${id}/refuse/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  newDevice({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/submissions/${payload.submission_id}/devices/`, payload.data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  deleteDevice({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/submissions/${payload.submission_id}/devices/${payload.device_id}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  storeDeviceData({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/submissions/${payload.submission_id}/devices/${payload.device_id}/`,
          payload.data
      )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchDeviceDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/submissions/${payload.submission_id}/devices/${payload.device_id}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchAllBuildings({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/buildings/`)
          .then((response) => {
            commit('UPDATE_BUILDINGS', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchBuildingDetails({commit}, code) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/buildings/${code}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  createBuilding({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/buildings/`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  updateBuilding({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/buildings/${payload.code}/`, payload.data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  deleteBuilding({commit}, code) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/buildings/${code}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  downloadAttachment({commit}, uuid) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/attachments/${uuid}/download/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  deleteAttachment({commit}, code) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/attachments/${code}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchAllVlans({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/vlans/`)
          .then((response) => {
            commit('UPDATE_VLANS', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchVlanDetails({commit}, code) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/vlans/${code}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchLabelDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/labels/${id}/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  // eslint-disable-next-line no-unused-vars
  updateLabel({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/labels/${payload.id}/`, payload.data)
          .then((response) => {
            commit("UPDATE_OR_CREATE_LABEL", response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },

   // eslint-disable-next-line no-unused-vars
  deleteLabel({commit}, id) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/labels/${id}/`)
          .then((response) => {
            commit("DELETE_LABEL", id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },

   // eslint-disable-next-line no-unused-vars
  downloadCsv({commit}, ids) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/buildings/export/`, {ids: ids }, {responseType: 'blob'})
          .then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Sites_export.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },


}

// Mutations
const mutations = {

  setJWT(state, jwt) {
    // When this updates, the getters and anything bound to them updates as well.
    state.currentJWT = jwt;
    localStorage.setItem("jwt", jwt)
  },
  SET_USER(state, user) {
    state.user = user

  },

  setTitle(state, title) {
    Vue.set(state, 'title', title);
  },
  SET_CURRENT_BUILDING(state, building) {
    state.current_building = building;
  },
  UPDATE_SUBMISSION(state, payload) {
    state.current_submission = payload
  },
  UPDATE_BUILDINGS(state, payload) {
    state.buildings = payload
  },
  UPDATE_FILTERED_BUILDINGS(state, payload) {
    state.filtered_buildings = payload
  },
  UPDATE_VLANS(state, payload) {
    state.vlans = payload
  },
  UPDATE_LABELS(state, payload) {
    state.labels = payload
  },
  ADD_LABEL(state, payload) {
    state.labels.push(payload)
  },
  UPDATE_FILTERS(state, payload) {
    state.current_filters = payload
  },
  SET_RELOCATING(state, val) {
    state.relocating = val
  },
  SET_RELOCATING_LOCATION(state, val) {
    state.relocated_location = val
  },
  UPDATE_OR_SET_BUILDING(state, current_building) {
    let index = state.filtered_buildings.findIndex(b => b.code === current_building.code)
    if (index !== -1) {
      state.filtered_buildings[index].geo = current_building.geo
    } else {
      console.log("You should not be here")
    }
  },
  UPDATE_OR_CREATE_LABEL(state, current_label) {
    let index = state.labels.findIndex(b => b.id === current_label.id)
    if (index !== -1) {
      Vue.set(state.labels, index, current_label );
    } else {
      state.labels.push(current_label)
    }
  },

  DELETE_LABEL(state, id) {
    let index = state.labels.findIndex(b => b.id === id)
    if (index !== -1) {
      Vue.delete(state.labels, index, id);
    }
  },


}


const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  getters: getters, actions: actions, mutations: mutations, state: state,
  strict: debug,
  plugins: []
})
