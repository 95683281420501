<template>
  <div id="app" class="site">
    <section class="hero is-primary is-small">

      <div class="hero-head">
        <b-navbar>
          <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img
                  src="@/assets/logo.png"
                  alt="Netwerkdiensten Vlaamse Overheid"
              >
            </b-navbar-item>
          </template>
          <template #start>
            <b-navbar-item @click="$router.push({name: 'buildings.overview.side'})">
              Gebouwen
            </b-navbar-item>
            <b-navbar-item v-if="isadmin" @click="$router.push({name: 'labels.overview'})">
              Labels
            </b-navbar-item>
            <b-navbar-item v-if="isadmin" @click="$router.push({name: 'submission.overview'})">
              Aanvragen
            </b-navbar-item>

          </template>

          <template #end>
            <b-navbar-item>
              Ingelogd als {{ username }}

            </b-navbar-item>
            <b-navbar-item>

              <a @click="logout" class=" is-light">
                Log uit
              </a>
            </b-navbar-item>

          </template>
        </b-navbar>

      </div>

      <!--      <div class="hero-body has-text-centered">-->
      <!--        <div class="container has-text-left" style="max-width: 750px">-->
      <!--          <title-bar :message="title.message" :back="title.back" :title-stack="title.stack"></title-bar>-->
      <!--        </div>-->
      <!--      </div>-->

    </section>

    <section class="section px-2">

      <div class="container">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </section>

    <div class="footer has-text-centered">
      ©{{ new Date().getFullYear() }} Netwerkdiensten Vlaamse Overheid
    </div>
  </div>
</template>

<script>

// import TitleBar from "@/components/TitleBar";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      open: true,
    }
  },

  mounted() {
    console.log(this.$route)
    this.$store.dispatch("fetchAllLabels")

  },

  computed: {

    username() {
      return this.$store.state.user?.username
    },
    isadmin() {
      return this.$store.state.user?.admin
    },
    title() {
      return this.$store.state.title
    },

  },

  methods: {

    logout() {
      window.location.href = "https://netwerkdiensten.eu.auth0.com/v2/logout?returnTo=https%3A%2F%2Fsites.netwerkdiensten.be"
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site {
  min-height: 100vh;
  display: flex;
  flex-direction: column
}

.site-content {
  flex: 1;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  background-color: #f3f3f3;
}


.hero-body {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
}


</style>
